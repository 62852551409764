import ReactionTimeTest from './speedTest';

function App() {
  return (
    <body>
      <ReactionTimeTest></ReactionTimeTest>
    </body>
  );
}

export default App;
