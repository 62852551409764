import React, { useState, useEffect } from "react";
import myAudio from "./beep.wav"; 

const ReactionTimeTest = () => {
  const [gameState, setGameState] = useState("waiting"); // 'waiting', 'ready', 'clicked', 'done'
  const [message, setMessage] = useState("발차기 반응 속도 측정");
  const [startTime, setStartTime] = useState(null);
  const [reactionTimes, setReactionTimes] = useState([]);
  const [attempts, setAttempts] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const [timerId, setTimerId] = useState(null)
  const total_trial = 3;
  const beep_sound = new Audio(myAudio);
  //const beep_sound = new Audio("https://www.soundjay.com/buttons/beep-01a.mp3");
  beep_sound.preload="auto";

  useEffect(() => {
    const handleKeyPress = () => {
      if (gameState === "waiting" && attempts === 0) {
        startGame();
      } else if (gameState === "ready") {
        handleEarlyPress();
      } else if (gameState === "clicked") {
        handleReaction();
      } else if (gameState === "done") {
        resetGame();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      clearTimeout(timeoutId);
    };
  }, [gameState, attempts, startTime]);

  const startGame = () => {
    if (attempts < total_trial) {
      setMessage("");
      setGameState("ready");

      // Random delay between 1 to 4 seconds
      const delay = Math.floor(Math.random() * 2500) + 1500; 
      beep_sound.currentTime = 0.1;

      const id = setTimeout(() => {
        beep_sound.play().then(
          setGameState("clicked"),
          setStartTime(Date.now()),
          setMessage("KICK!")
        )
      }, delay);


      setTimeoutId(id);


    }
  };

  const handleEarlyPress = () => {
    clearTimeout(timeoutId); // Cancel the timer
    setMessage("DON'T HURRY");
    setGameState("done");
  };

  const handleReaction = () => {
    const endTime = Date.now();
    const reactionTime = endTime - startTime;
    clearTimeout(timerId);
    setReactionTimes([...reactionTimes, reactionTime]);
    setAttempts(attempts + 1);

    if (attempts + 1 < total_trial) {
      setMessage(`${reactionTime} ms`);
      setGameState("waiting");
      setTimeout(() => startGame(), 2000); // Automatically start the next round after 1 second
    } else {
      const averageTime = Math.round(
        [...reactionTimes, reactionTime].reduce((a, b) => ((a<b)?a:b), 5000)
      );
      setMessage(`${averageTime} ms`);
      setGameState("done"); 
    }
  };

  const resetGame = () => {
    setReactionTimes([]);
    setAttempts(0);
    setMessage("발차기 반응 속도 측정");
    setGameState("waiting");
  };


  return (
    <div>
      <div className="p-10 text-6xl fixed text-white"> {attempts} / {total_trial} </div>
      <div
        className={`w-screen h-screen flex justify-center items-center text-white text-xl cursor-pointer select-none ${
          gameState === "clicked" ? "bg-green-500" : (
            gameState === "ready" ? "bg-red-500" : (
              gameState === "done" ? "bg-blue-500" :
              "bg-gray-500"
            )
          )
        }`}
      >
        
        <div className="text-center text-8xl">
          {message}
        </div>
      </div>
    </div>
    
  );
};

export default ReactionTimeTest;
